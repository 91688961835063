import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { useSpring, animated } from 'react-spring'
import {Helmet} from "react-helmet"

import device from "../styles/device"
import colors from "../styles/colors"
import fonts from "../styles/fonts"

import Layout from "../layout"
import LayoutPages from './layoutPages'

//dataLang:
import langDataEs from '../data/languages/es'
import langDataCa from '../data/languages/ca'
import langDataEn from '../data/languages/en'

const Page = styled.div`
  font-family: ${fonts.poppins};
  color: ${colors.tertiary};
  max-width: 1024px;

  margin: auto;
  padding: 100px 8.3333333%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  margin: 100px auto 0;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const Container = styled.div`
  margin: 0 -10px;
`

const Title = styled.h1`
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
  margin: 0 0 60px 0;

  padding: 0 10px;

  @media ${device.phone} {
    font-size: 36px;
    line-height: 42px;
  }
`

const PageTemplate = ({data, pageContext}) => {
  
  const {sideMenu, cookies, footer } = (pageContext.lang === "es" && langDataEs) || (pageContext.lang === "ca" && langDataCa) || (pageContext.lang === "en" && langDataEn)

  const titleProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(-200px)`
    },
    to: {
      opacity: 1,
      transform: `translateY(0px)`
    }
  })

  const contentProps = useSpring({
    from: {
      opacity: 0, 
      transform: `translateY(200px)`
    },
    to: {
      opacity: 1,
      transform: `translateY(0px)`
    }
  })

  return (
    <Layout sideMenu={sideMenu} cookies={cookies} footer={footer}>
      <Helmet>
          <title>{data.wordpressPage.yoast_meta.yoast_wpseo_title}</title>
          <meta name="description" content={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc} />
      </Helmet>
      <Page>
        <Container>
            <animated.div style={titleProps}><Title dangerouslySetInnerHTML={{ __html: data.wordpressPage.title }} /></animated.div>
            <animated.div style={contentProps}><LayoutPages content={data.wordpressPage.content} /></animated.div>
        </Container>
      </Page>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      yoast_meta{
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
